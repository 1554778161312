
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
/* STORE */
import { auth, notifications, balances, remittance, countries } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';
/* INTERFACES */
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import Loader from '@/components/utilities/Loader.vue';
import ContactWhatsappModal from '@/components/utilities/ContactWhatsappModal.vue';

@Component({
    components: {
        SvgIcon,
        Loader,
        ContactWhatsappModal
    },
})
export default class Header extends Vue {

  menuSideBar = false;
  links= [
    {
      name: 'Inicio',
      to: '/inicio',
      component: 'Home',
      icon: 'icon-home'
    },
    {
      name: 'Nosotros',
      to: '/nosotros',
      component: 'Us',
      icon: 'icon-users'
    },
    // {
    //   name: 'Gana',
    //   to: '/gana',
    //   component: 'Referrals',
    //   icon: 'icon-award'
    // },
    {
      name: 'Contacto',
      to: '/contacto',
      component: 'Contacts',
      icon: 'icon-phone'
    },  
  ]
  showContactModal = false;

    created(){ 
     if (!process.env.VUE_APP_LOGIN_DISABLED)
      this.links.push({
        name: 'Blog',
        to: '/blog',
        component: 'Blog',
        icon: 'document'
      },)
    }

    goToLogin(){
      if (process.env.VUE_APP_LOGIN_DISABLED){
        this.showContactModal = true;
      }
      else {
        if (this.isAuthenticated)
          this.$router.push({name: 'Dashboard'}).catch(()=>{})
        else 
          this.$router.push({name: 'Login'}).catch(()=>{})
      }
    }

    changeShowContactModal(newVal: boolean){
      this.showContactModal = newVal;
    }

    get buttonText(){
      if (process.env.VUE_APP_LOGIN_DISABLED) return 'Escríbenos'
      else return 'Inicia Sesión'
    }

    @auth.Getter(AuthMethods.getters.GET_AUTHENTICATION)
    isAuthenticated!: boolean;

}
