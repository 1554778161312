
import { Component, Vue, Watch } from 'vue-property-decorator';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import Header from '@/modules/landingPage/components/eu/layout/Header.vue';
import Footer from '@/modules/landingPage/components/eu/layout/Footer.vue';
/* INTERFACES */
/* STORE */
@Component({
  components: {
      SvgIcon,
      Header,
      Footer,
  },
})
export default class CompliancePolicies extends Vue {
}
